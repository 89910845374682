.madero-style {

  #plan-banner {
    .btn {
      min-height: 0;
    }
  }

  select.form-control.has-placeholder {
    &.ng-empty {
      color: $gray-500;
    }
  }

  .btn-default-icon {
    @extend .btn-default;

    &:active,
    &:focus,
    &:hover {
      background: $white;
      border-color: $rise-green;
    }

    svg {
      margin: 0;
      vertical-align: middle;
      path {
        fill: $rise-green;
      }
    }
  }

  // dropdown selector button
  .btn-select {
    text-align: left;
    font-weight: normal;

    .text-muted {
      color: $gray-500;
    }
  }

  input:disabled+label {
    color: $gray-500;
    cursor: not-allowed;
  }

  .btn-dropdown {
    // duplicate "dropdown-menu > ul > li > a" styles
    color: $gray-800;
    padding: 10px;
    margin: 0;
    background-color: $white;
    border: 0;
    width: 100%;
    text-align: left;

    &:disabled {
      color: $gray-500;
      cursor: not-allowed;
    }

    &:hover:enabled,
    &:focus:enabled {
      // duplicate "dropdown-menu > ul > li > a:hover" styles
      background-color: $pale-shadow-100;
      color: $rise-blue;
      // duplicate <a> styles
      text-decoration: underline;
      text-align: left;
      width: 100%;
    }
  }

  .text-danger {
    color: $danger;
  }

  .text-gray {
    color: $gray-500; // TODO: change to $gray-700
  }

  .bg-lighter-gray {
    background-color: $gray-100;
  }

  last-modified .text-muted {
    @extend .text-gray;
  }

  .light-border {
    border-top: 1px solid $gray-200;
  }

  .border-round {
    border: 1px solid $gray-300;
    border-radius: 4px;
  }

  .border-bottom {
    border-bottom: 1px solid $gray-300;
  }

  .border-top {
    border-top: 1px solid $gray-300;
  }

  .border-container {
    @extend .border-round;
  }

  .scrollable-list {
    min-height: auto;

    .list-group-item {
      @extend .border-top;

      border-radius: 0px;

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        @extend .border-bottom;

        margin-bottom: -1px !important;
      }
    }

    &.horizontal-scroll {
      overflow-x: auto;
    }

    .table {
      border-collapse: separate;
      border-spacing: 0 4px;
    }

    .table>tbody>tr>td, .table>thead>tr>th {
      @extend .u_padding-md-vertical;
      @extend .u_nowrap;
      vertical-align: middle;
      padding-left: 12px;
      padding-right: 12px;
    }

    .table.dense>tbody>tr>td, .table.dense>thead>tr>th {
      @extend .u_padding-sm-vertical;
    }

    .table>thead {
      box-shadow: 0px 4px 5px -2px rgba(107, 114, 128, 0.20);
    }

    .table>thead>tr {
      height: 52px;
      color: $gray-700;

      > th {
        font-weight: 500;
        font-size: 1.4rem;
        border-bottom: 0;

        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }

        > div.fixed-width {
          width: 400px;
        }

        & > td:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }

    .table>tbody {
      &::before {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background: transparent;
      }
    }

    .table>tbody>tr {
      @extend .border-top;
      &:not(.placeholder) {
        background: $gray-50;
        height: 68px;
        border-radius: 4px;

        a {
          color: $black;
          text-decoration: none;

          &:hover {
            color: $black;
            text-decoration: underline;
          }
        }

        &.clickable:hover {
          background: $gray-100;
          cursor: pointer;
        }
      }
      &>td, &.borderless {
        border-top: none;
      }

      &>td {
        min-height: 68px;
      }

      & > td:first-child {
        padding-left: 16px;
      }
      & > td:last-child {
        padding-right: 16px;
      }
      &.selected {
        background: $gray-100;
      }
    }

    .table>tbody>tr {
      @extend .border-top;
      &:not(.placeholder) {
        background: $gray-50;
        height: 68px;
        border-radius: 4px;

        a {
          color: $black;
          text-decoration: none;

          &:hover {
            color: $black;
            text-decoration: underline;
          }
        }

        &.clickable:hover {
          background: $gray-100;
          cursor: pointer;
        }
      }
      &>td, &.borderless {
        border-top: none;
      }

      &>td {
        min-height: 68px;
      }

      & > td:first-child {
        padding-left: 16px;
      }
      & > td:last-child {
        padding-right: 16px;
      }
      &.selected {
        background: $gray-100;
      }
    }

    &.rounded-rows {
      td {
        border-style: none;
      }
      td:first-child {
          border-left-style: none;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
      }
      td:last-child {
          border-right-style: none;
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
      }
    }
  }

  .light-border {
    border-color: $gray-200;
  }

  .light-border {
    border-color: $gray-200;
  }

  .btn-danger {
    background: $danger;
    border-color: $danger;
  }

  .btn.btn-auto {
    font-weight: normal;
    min-height: auto;
  }

  .btn-toolbar {
    min-width: 160px;
    margin-left: auto;
  }

  .btn-toolbar-wide {
    min-width: 200px;
    margin-left: auto;
  }

  .button-toolbar {
    & > * {
      @extend .mr-3;

      &:last-child{
        @extend .mr-0;
      }
    }
  }

  .button-row {
    & > * {
      @extend .mr-md-3;

      &:last-child{
        @extend .mr-0;
      }
    }
  }

  @media (max-width: 768px) {
    .layout-md-folded {
      display: block;
    }
    .button-toolbar-md-folded {
      display: block;

      & > * > * {
        margin-top: 1rem !important;
      }

      & > *:first-child > * {
        margin-top: 0 !important;
      }

      .button-toolbar {
        display: block;
      }

      .btn-toolbar, .btn-toolbar-wide {
        width: 100%;
      }
    }
  }

  .streamline-component-icon {
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    top: 3px;
  }

  .btn {
    .streamline-component-icon {
      top: 1px;
    }
  }

  .rv-sortable-item {
    &.disabled {
      background-color: $gray-100;
    }

    .rv-sortable-handle {
      cursor: move;
      cursor: -webkit-grabbing;

      streamline-icon {
        display: block;

        svg {
          fill: $gray-500;
        }
      }
    }
  }

  .scrollable-list {
    .rv-sortable-handle {
      @extend .pr-1;
    }
  }

  .progress {
    height: 10px;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid $gray-500;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .progress-bar {
    width: 50%;
    background: $rise-green;
    animation: none;
    height: 100%;
    box-shadow: none;
  }

  .fields-preview-panels {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-flow: column-reverse;

    @media (min-width: $screen-md) {
      display: flex;
      flex-flow: row;
      height: calc(100vh - var(--body-height));
    }

    .preview-container {
      width: 100%;
      overflow: auto;
      padding-right: 8px;

      @media (min-width: $screen-md) {
        @extend .ml-lg-4;

        width: 100%;
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: var(--scrollbar-size);
      }

      &::-webkit-scrollbar-track {
        background: $gray-400;
        border-radius: var(--scrollbar-size);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--scrollbar-size);
        background: $gray-600;
      }
    }

    .fields-container {
      --sidebar-width: 450px;

      height: 100%;

      @media (min-width: $screen-md) {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: var(--sidebar-width);
        width: var(--sidebar-width);
      }
    }
  }

  .preview-embed {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
    }
  }

  &.tooltip {
    opacity: 1;
    white-space: normal;

    // This overrides the `pointer-events: none` style that ngx-bootstrap dynamically adds to the page,
    // which suppresses all mouse interactions with elements contained within a tooltip.
    pointer-events: all !important;

    .scrollable-list {
      min-height: 150px;
      max-height: 150px;
    }

    &.basic-tooltip,
    &.thumbnail-tooltip {
      opacity: 1;
      z-index: 9999;
      .tooltip-arrow {
        display: none;
      }
      .tooltip-inner {
        padding: 10px;
        border: 0px;
        box-shadow: 0px 0px 5px 0px $dark-blue-shadow-500;
        opacity: 1;
        line-height: 1.5;
        background-color: $white;
        overflow: hidden;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }

    &.basic-tooltip {
      .tooltip-inner {
        text-align: left;
        max-width: 374px;
        width: 374px;

        @media (max-width: $screen-sm) {
          width: 350px;
          max-width: 350px;
        }
      }
    }

    &.tooltip-share-options {
      margin-left: -106px;

      .tooltip-inner {
        text-align: left;
        padding: 10px;
        border: 0px;
        box-shadow: 0px 0px 5px 0px $dark-blue-shadow-500;
        opacity: 1;
        max-width: 374px;
        width: 374px;

        .close {
          height: 10px;
          width: 10px;

          svg {
            fill: $gray-500;
          }
        }

        .input-group-btn {
          width: 1%;
        }
      }

      &.bottom {
        margin-top: -8px;
      }

      .tooltip-arrow {
        display: none;
      }

      &.tooltip-sm {
        .tooltip-inner {
          width: 303px;
        }
      }
    }

    &.tooltip-schedule-selector {
      @extend .tooltip-share-options;

      .tooltip-inner {
        max-width: 360px;
        width: 360px;
        padding: 15px;
        font-size: 14px;

        hr {
          border-top: 1px solid $gray-500;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      &.bottom {
        margin-top: -9px;
      }
    }

    &.tooltip-schedule-selector {
      @extend .tooltip-share-options;

      .tooltip-inner {
        max-width: 360px;
        width: 360px;
        padding: 15px;
        font-size: 14px;

        hr {
          border-top: 1px solid $gray-500;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      &.bottom {
        margin-top: -9px;
      }
    }
  }

  .popover {

    border-radius: 0;
    box-shadow: none;

    .popover-content div {
      @extend .u_ellipsis-md;
    }

    .popover-arrow {
      display: none;
    }

    &.timepicker {
      border-radius: 5px;
      margin-top: 3px;
      padding: 5px 0;
      width: 200px;
    }

    &.upgrade-popover {
      border-radius: 6px;
      margin-top: -4px;
      background-color: $rise-blue;
      border-color: $rise-blue;
      color: $white;

      &.right {
        .arrow {
          left: -9px;
          border-right-color: $rise-blue;

          &::after {
            border-right-color: $rise-blue;
          }
        }
      }

      .popover-arrow {
        display: block;
      }

      .popover-content a {
        color: $white;
      }
    }
  }

  &.action-sheet {
    &.tooltip-share-options {
      padding: 20px;
      box-shadow: 0px 0px 5px 0px $dark-blue-shadow-500;

      .close {
        height: 15px;
        width: 15px;

        svg {
          fill: $gray-500;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .multi-actions-panel {
    background-color: $gray-100;

    .close {
      width: auto;

      svg {
        fill: $gray-500;
      }

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }

  .multi-actions-progress-panel {
    @extend .mx-auto;

    max-width: 583px;
  }

  label {
    &.btn {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 0;
    }
  }
}

.search-buttons {
  display: flex;

  >* {
    flex: 1 1 auto;
  }

  @media screen and (max-width: $screen-sm) {
    width: 100%;
    flex-direction: column;

    >* {
      width: 100% !important;
    }
  }
}

.form-control.readonly-clean[readonly] {
  background-color: $white;
}

//Show Focus
.show-focus {
  &:hover,
  &:focus {
    background: transparentize($rise-blue,.9);
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}
